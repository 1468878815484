import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { format } from 'date-fns';
import { React, useEffect, useState } from "react";
import { Col } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { APIfacturas, APIOcr } from "../../../../../../services/api/facturas";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

export default function FolderDialog({
    movimiento,
    onConfirmAction,
    onClose,
    updateMov,
    state
}) {
    const {
        register,
        control,
        setValue,
        watch,
        formState: { errors },
        handleSubmit
    } = useForm({
    });

    const [facturas, setFacturas] = useState([]);
    const [viewerSrc, setViewerSrc] = useState(null);
    const [text, setText] = useState('');
    const tipoRetencionSeleccionada = watch("tipo_retencion");
    const [selectedViesNombre, setSelectedViesNombre] = useState(null);
    const [selectedVies, setSelectedVies] = useState(null);

    const handleFileChange = async (e) => {
        const file = e.target.files[0];

        if (file) {
            try {
                // Muestra la vista previa del archivo
                setViewerSrc(URL.createObjectURL(file));

                // Crea un objeto FormData y agrega el archivo con la clave 'pdf'
                const formData = new FormData();
                formData.append('pdf', file);

                // Llamada a la API utilizando fetch o axios (dependiendo de tu implementación)
                const response = await APIOcr.post(formData);
                // Actualizar los valores en los campos de entrada
                setValue('numero', response.numeroFactura || "");
                setValue('total', response.totalIva || "");
                setValue('date', response.fechaFactura || "");
                setValue('base', response.base || "");
                setValue('iva', response.iva || "");
            } catch (error) {
                console.error('Error:', error.message);
            }
        }
    };

    const handleConfirm = (confirm) => {
        onConfirmAction(confirm);
    };

    const handleClose = () => {
        onClose();
    };

    const showErrorToast = (message) => {
        toast.error(message, {
            position: 'top-right', // You can customize the position
        });
    };

    function flattenArray(arr) {
        if (!Array.isArray(arr)) {
            return [];
        }
        return arr.reduce((acc, val) => acc.concat(val), []);
    }

    const fetchDataVies = async () => {
        try {
            if (!selectedViesNombre) {
                const listVies = await APIfacturas.listVies();
                setSelectedViesNombre(listVies.vies);
            }
        } catch (err) {
            showErrorToast('No se han podido obtener los vies');
        }
    };

    useEffect(() => {
        APIfacturas.get(movimiento.id).then((data) => {
            setFacturas(data.facturas);
        }).catch((err) => {

        })
        fetchDataVies();
    }, []);

    const downloadFacturaFromS3 = async (factura) => {
        const docResult = await APIfacturas.download(factura.id);

        if (docResult) {
            const url = window.URL.createObjectURL(new Blob([docResult]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', factura.name);
            document.body.appendChild(link);
            link.click();
        }
    }

    const removeFactura = async (factura) => {
        const docResult = await APIfacturas.delete(factura.id);
        setFacturas(docResult.facturas)
        updateMov(movimiento.index, docResult.movimiento)
    }

    const handleChangeBaseNoSujeta = (event) => {
        let baseNoSujeta = event.target.value;
        baseNoSujeta = baseNoSujeta.replace(',', '.');
        setValue('baseNoSujeta', baseNoSujeta);
    };

    const handleChangeTipoIva = (event) => {
        let total = event.target.value;
        total = total.replace(',', '.');
        let base = 0;
        let tipo_iva = movimiento.tipo_iva;
        let iva = 0;
        setValue('tipo_iva', tipo_iva);
        if (tipo_iva === 21) {
            base = (total / 1.21).toFixed(2);
            setValue('base', base);
        } else if (tipo_iva === 10) {
            base = (total / 1.10).toFixed(2);
            setValue('base', base);
        } else if (tipo_iva === 0) {
            iva = 0;
            base = total;
            setValue('base', total);
        }
        iva = (total - base).toFixed(2)
        setValue('iva', iva);
        setValue('total', total);
    };

    const submit = async (data, e) => {
        e.preventDefault();
        const documentoForm = new FormData();

        Object.entries(data).forEach((value) => {
            if ((value[0] === 'documento') && value[1]?.length > 0) {
                documentoForm.append(value[0], value[1][0])
            } else { documentoForm.append(value[0], value[1]) }
        });

        documentoForm.append("id", movimiento.id)
        APIfacturas.post(documentoForm).then((res, error, error2) => {
            const dataRes = res.data
            setFacturas(dataRes.facturas)
            updateMov(movimiento.index, dataRes.movimiento)
        }).catch((err) => {
            if (err.response && err.response.data && err.response.data.error) {
                showErrorToast(err.response.data.error);
            } else if (err.code === "ERR_BAD_REQUEST") {
                showErrorToast('Importes inválidos');
            }
        })

    }
    return (
        <Dialog
            open={state}
            onClose={handleClose}
            aria-labelledby="confirm-title"
            aria-describedby="confirm-description" maxWidth="lg">
            <DialogTitle id="confirm-title">
                <p className="text-uppercase fw-bold">{movimiento.nombre}</p>
                <p>Adjunta aquí facturas o elimina las ya existentes de {movimiento.nombre} a fecha {format(new Date(movimiento.fecha), 'dd/MM/yyyy')}</p>
            </DialogTitle>
            <DialogContent style={{ width: '90vw', maxWidth: 'none' }}>
                <DialogContentText id="confirm-description" className="d-flex">
                    <Col md={3} style={{ marginTop: "2rem" }}>
                        <form className="form col-12 mb-4" onSubmit={handleSubmit(submit)}>
                            <div className="d-flex flex-wrap justify-content-between">
                                <div className="form-group" style={{ marginBottom: "0rem" }}>
                                    <label htmlFor="documento">Documento a subir</label>
                                    <input
                                        type="file"
                                        className="form-control"
                                        {...register("documento", { required: true, maxLength: 255 })}
                                        defaultValue={''}
                                        onChange={handleFileChange} />

                                    <div className="form-invalid">
                                        {(errors.documento) && "El documento es obligatorio!"}
                                    </div>
                                    <hr></hr>
                                </div>
                                <div className="form-group" style={{ marginBottom: "1rem", flex: "0 0 48%", width: "50%" }}>
                                    <label htmlFor="vies" style={{ marginTop: "5px" }}>Vies</label>
                                    <Controller
                                        name="vies"
                                        control={control}
                                        rules={{ required: true }}
                                        defaultValue={null}
                                        render={({ field }) => (
                                            <Autocomplete
                                                {...field}
                                                options={flattenArray(selectedViesNombre)}
                                                getOptionLabel={(option) => option.nombre}
                                                onChange={(event, newValue) => {
                                                    setValue("vies", newValue ? newValue.id : null);
                                                    setSelectedVies(newValue ? newValue.id : null);
                                                }}
                                                value={flattenArray(selectedViesNombre).find((item) => item.id === selectedVies) || null}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Selecciona un vies"
                                                        variant="outlined"
                                                    />
                                                )}
                                                noOptionsText="No hay opciones para los criterios introducidos"
                                            />
                                        )}
                                    />
                                    <div className="form-invalid">
                                        {(errors.vies) && "Selecciona un vies"}
                                    </div>
                                </div>
                                <div className="form-group" style={{ marginBottom: "1rem", flex: "0 0 48%", width: "50%" }}>
                                    <label style={{ marginTop: "5px" }} htmlFor="localizacion">Localización</label>
                                    <select id="localizacion" {...register("localizacion", { required: true })}>
                                        <option value="1">Lleida</option>
                                        <option value="2">Balaguer</option>
                                        <option value="3">Zaragoza</option>
                                        <option value="4">Vinarós</option>
                                    </select>
                                </div>
                                <div className="form-group" style={{ marginBottom: "1rem", flex: "0 0 48%", width: "50%" }}>
                                    <label style={{ marginTop: "5px" }} htmlFor="departamento">Departamento</label>
                                    <select id="departamento" {...register("departamento", { required: true })}>
                                        <option value="1">Finanzas</option>
                                        <option value="2">Incidencias</option>
                                        <option value="3">IT</option>
                                        <option value="4">Recambistas</option>
                                        <option value="5">RRHH</option>
                                        <option value="6">Canal Venta Online</option>
                                        <option value="7">Canal Venta Dropshipping</option>
                                        <option value="8">Producción</option>
                                        <option value="9">Headquarters</option>
                                    </select>
                                </div>
                                <div className="form-group" style={{ marginBottom: "1rem", flex: "0 0 48%", width: "50%" }}>
                                    <label style={{ marginTop: "5px" }} htmlFor="clasif">Clasificación</label>
                                    <select id="clasif" {...register("clasif", { required: true })}>
                                        <option value="1">Raw Material</option>
                                        <option value="2">Online Sales</option>
                                        <option value="3">Dropshipping</option>
                                        <option value="4">CoGS Dropshipping</option>
                                        <option value="5">COGS Online Sales</option>
                                        <option value="6">Transporte</option>
                                        <option value="7">Alquiler</option>
                                        <option value="8">Otros Gastos</option>
                                        <option value="9">Servicios Consultoria</option>
                                        <option value="10">Servicios Notaria</option>
                                        <option value="11">Servicios Asesores</option>
                                        <option value="12">Servicios Comerciales</option>
                                        <option value="13">Marketing</option>
                                        <option value="14">Suministros</option>
                                        <option value="15">Material de Oficina</option>
                                        <option value="16">Otros Tributos</option>
                                        <option value="17">R&D</option>
                                        <option value="18">Gastos Financieros</option>
                                        <option value="19">Ingresos Financieros</option>
                                        <option value="20">Resultados Extraordinarios</option>
                                    </select>
                                </div>
                                <div className="form-group" style={{ marginBottom: "0rem", display: "block" }}>
                                    <label htmlFor="num">IVA NO deducible</label>&nbsp;
                                    <input id="iva_deducible" type="checkbox" {...register("iva_deducible", { required: false })} />
                                </div>
                                <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                    <label htmlFor="num">Número de factura</label>
                                    <input id="num" type="text" {...register("numero", { required: true })} />
                                    <div className="form-invalid">
                                        {(errors.numero) && "Introduce un numero de factura"}
                                    </div>
                                </div>
                                <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                    <label htmlFor="date">Fecha Factura</label>
                                    <input id="date" type="text" {...register("date", { required: true })} placeholder="dd/mm/yyyy" />
                                    <div className="form-invalid">
                                        {(errors.date) && "Introduce una fecha"}
                                    </div>
                                </div>
                                <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                    <label style={{ marginTop: "12px" }} htmlFor="tipo_iva">Tipo IVA</label>
                                    <select id="tipo_iva" {...register("tipo_iva", { required: true })}
                                        defaultValue={movimiento.tipo_iva} style={{ opacity: "0.5", cursor: "not-allowed" }} disabled>
                                        <option value="">Selecciona el tipo de IVA</option>
                                        <option value="21">21%</option>
                                        <option value="10">10%</option>
                                        <option value="0">0%</option>
                                    </select>
                                    <div className="form-invalid">
                                        {(errors.tipo_iva) && "Selecciona tipo de IVA"}
                                    </div>
                                </div>
                                <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                    <label style={{ marginTop: "12px" }} htmlFor="base">Base Imp.</label>
                                    <input id="base" type="text" {...register("base", { required: false })} style={{ opacity: "0.5", cursor: "not-allowed" }} disabled />
                                    <div className="form-invalid">
                                        {(errors.base) && "Introduce un importe base!"}
                                    </div>
                                </div>
                                <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                    <label style={{ marginTop: "12px" }} htmlFor="iva">IVA</label>
                                    <input id="iva" type="text" {...register("iva", { required: false })} style={{ opacity: "0.5", cursor: "not-allowed" }} disabled />
                                    <div className="form-invalid">
                                        {(errors.iva) && "Introduce un importe iva!"}
                                    </div>
                                </div>
                                <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                    <label style={{ marginTop: "12px" }} htmlFor="tipo_retencion">Retención</label>
                                    <select id="tipo_retencion" {...register("tipo_retencion", { required: false })}
                                        defaultValue={movimiento.tipo_retencion}>
                                        <option value="">Selecciona el tipo de Retención</option>
                                        <option value="21">21%</option>
                                        <option value="19">19%</option>
                                        <option value="15">15%</option>
                                        <option value="10">10%</option>
                                        <option value="7">7%</option>
                                    </select>
                                </div>
                                {tipoRetencionSeleccionada && (
                                    <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                        <label style={{ marginTop: "12px" }} htmlFor="baseNoSujeta">Base No Sujeta</label>
                                        <input id="baseNoSujeta" type="text" {...register("baseNoSujeta", { required: false })} onChange={handleChangeBaseNoSujeta} />
                                        <div className="form-invalid">
                                            {(errors.baseNoSujeta) && "Introduce una base no sujeta!"}
                                        </div>
                                    </div>
                                )}
                                <div className="form-group" style={{ marginBottom: "0rem", flex: "0 0 48%", width: "50%" }}>
                                    <label style={{ marginTop: "12px" }} htmlFor="total">Importe total con IVA</label>
                                    <input id="total" type="text" {...register("total", { required: true })} onChange={handleChangeTipoIva} />
                                    <div className="form-invalid">
                                        {(errors.total) && "Introduce un importe total!"}
                                    </div>
                                </div>
                            </div>

                            <div className="w-100 d-flex justify-content-end mt-2">
                                <button type="submit" className="btn btn-primary">Procesar</button>
                            </div>
                        </form>
                    </Col>
                    <Col md={9}>
                        {viewerSrc ? (
                            // Utiliza un elemento iframe u object según el tipo de archivo
                            viewerSrc.endsWith(".pdf") ? (
                                <iframe title="PDF Viewer" src={viewerSrc} width="100%" height="100%"></iframe>
                            ) : (
                                <object style={{ marginTop: "3rem", marginBottom: "21rem", marginLeft: "0.2rem" }} data={viewerSrc} type="application/pdf" width="80%" height="80%">
                                    <p>Visor no compatible con el tipo de archivo.</p>
                                </object>
                            )
                        ) : (
                            <p></p>
                        )}
                    </Col>
                </DialogContentText>

                {facturas && facturas.map((factura, index) => {
                    return <div><button onClick={() => { downloadFacturaFromS3(factura) }}>Factura {factura.numero}</button> Total: {factura.total}€ <button onClick={() => { removeFactura(factura) }}>Eliminar</button></div>
                })}
            </DialogContent>
        </Dialog>
    );
}